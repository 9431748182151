import React, { useState } from "react";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

function Login() {
  const [showPassword, setShowPassword] = useState(true);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate()
  function handleData(e) {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  }

  function SubmitData(e) {
    navigate("/admin/dashboard")
    // e.preventDefault();
    // alert(`
    // email: ${data.email}
    // password : ${data.password}

    // `);
  }

  return (
    <section id="login">
      <div className="container mx-auto p-4 flex justify-center items-center h-screen">
        <div className="bg-white w-full max-w-md p-6 rounded-xl boxTransparent">
          <div className="mb-8">
            <h2 className="text-white text-xl font-bold text-center">Admin Login</h2>
            <p className="text-white text-[13px] text-center">Welcome back! Unlock the power of administrative controls.</p>
          </div>
          <form action="" onSubmit={SubmitData}>
            <div className="grid mb-5">
              <label htmlFor="" className="text-sm text-white">
                Email :<sup className="text-red-600 ml-1 text-[14px]">*</sup>
              </label>
              <div className="bg-slate-100 p-2 rounded">
                <input
                  type="email"
                  placeholder="Enter Email..."
                  name="email"
                  onChange={handleData}
                  className="bg-transparent outline-none text-sm w-full h-full"
                />
              </div>
            </div>

            <div className="grid mb-5">
              <label htmlFor="" className="text-sm text-white">
                Password<sup className="text-red-600 ml-1 text-[14px]">*</sup>
              </label>
              <div className="bg-slate-100 p-2 flex rounded">
                <input
                  type={showPassword ? "password" : "text"}
                  placeholder="Enter Password..."
                  name="password"
                  onChange={handleData}
                  className="bg-transparent outline-none text-sm w-full h-full"
                />
                <div
                  className="cursor-pointer text-lg w-6 h-6 flex justify-center items-center"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <span>{showPassword ? <FaEyeSlash /> : <FaEye />}</span>
                </div>
              </div>
              <div className="flex justify-end mt-1 w-full">
                <Link
                  to={"/auth-admin/forget-password"}
                  className="text-sm text-blue-600 hover:underline duration-300"
                >
                  Forget password ?
                </Link>
              </div>
            </div>

            <button
              type="submit"
              className="bg-white text-pink-600 font-semibold block py-2 w-full max-w-[150px] mt-6 mx-auto rounded-full hover:bg-slate-200 transition duration-300"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Login;

