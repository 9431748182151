import { Button } from "@mui/material";
import React, { useState } from "react";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { RiLockPasswordFill } from "react-icons/ri";


function ChangePassword() {
    const [showOldPassword, setShowOldPassword] = useState(true);
    const [showNewPassword, setShowNewPassword] = useState(true);
    const [showConfirmPassword, setShowConfirmPassword] = useState(true);
    const [data, setData] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });

    const navigate = useNavigate()
    function handleData(e) {
        const { name, value } = e.target;
        setData((prev) => {
            return { ...prev, [name]: value };
        });
    }

    function SubmitData(e) {
        navigate("/admin/dashboard")
        // e.preventDefault();
        // alert(`
        // email: ${data.email}
        // password : ${data.password}

        // `);
    }
    return (
        <main className='main-container'>
            <div className='rounded-xl p-4 text-black' style={{ boxShadow: "0px 0px 4px 1px lightgray" }}>
                <div className='main-title mb-7'>
                    <h3 className='text-black text-xl font-bold pb-2'>Change Password</h3>
                </div>

                <form action="">
                    <div className="mb-7">
                        <label htmlFor="" className="text-sm text-black font-semibold">
                            Old Password<sup className="text-red-600 ml-1 text-[14px]">*</sup>
                        </label>
                        <div className="bg-slate-100 p-2 flex rounded w-full md:w-1/2 border border-slate-300 items-center">
                            <input
                                type={showOldPassword ? "password" : "text"}
                                placeholder="Enter Password..."
                                name="oldPassword"
                                value={data.oldPassword}
                                onChange={handleData}
                                required
                                className="bg-transparent outline-none text-sm w-full h-full"
                            />
                            <div
                                className="cursor-pointer text-lg w-6 h-6 flex justify-center items-center"
                                onClick={() => setShowOldPassword(!showOldPassword)}
                            >
                                <span>{showOldPassword ? <FaEyeSlash /> : <FaEye />}</span>
                            </div>
                        </div>
                    </div>

                    {/* new password  */}
                    <div className="mb-7">
                        <label htmlFor="" className="text-sm text-black font-semibold">
                            New Password<sup className="text-red-600 ml-1 text-[14px]">*</sup>
                        </label>
                        <div className="bg-slate-100 p-2 flex  rounded w-full md:w-1/2 border border-slate-300 items-center">
                            <input
                                type={showNewPassword ? "password" : "text"}
                                placeholder="Enter Password..."
                                name="newPassword"
                                value={data.newPassword}
                                onChange={handleData}
                                required
                                className="bg-transparent outline-none text-sm w-full h-full"
                            />
                            <div
                                className="cursor-pointer text-lg w-6 h-6 flex justify-center items-center"
                                onClick={() => setShowNewPassword(!showNewPassword)}
                            >
                                <span>{showNewPassword ? <FaEyeSlash /> : <FaEye />}</span>
                            </div>
                        </div>
                    </div>
                    {/* Confirm Password  */}
                    <div className="mb-7">
                        <label htmlFor="" className="text-sm text-black font-semibold">
                            Confirm Password<sup className="text-red-600 ml-1 text-[14px]">*</sup>
                        </label>
                        <div className="bg-slate-100 p-2 flex  rounded w-full md:w-1/2 border border-slate-300 items-center">
                            <input
                                type={showConfirmPassword ? "password" : "text"}
                                name="confirmPassword"
                                value={data.confirmPassword}
                                onChange={handleData}
                                required
                                className="bg-transparent outline-none text-sm w-full h-full"
                            />
                            <div
                                className="cursor-pointer text-lg w-6 h-6 flex justify-center items-center"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                <span>{showConfirmPassword ? <FaEyeSlash /> : <FaEye />}</span>
                            </div>
                        </div>
                    </div>

                    <div className='mb-4'>
                        <Button onClick={() => navigate("/admin/dashboard")} type='submit' className='w-full md:w-1/2 flex font-extrabold justify-center items-center gap-2' variant='contained' sx={{ padding: "8px", backgroundColor: "#db2777" }}>
                            <RiLockPasswordFill className='text-white text-lg' /> Change Password </Button>
                    </div>
                </form>
            </div>
        </main>
    )
}

export default ChangePassword