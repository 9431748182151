import React from 'react'
import { TextField } from '@mui/material'

export default function TextInput({ label, required, height, width, ...rest }) {
    return (
        <TextField
            required={required}
            id="outlined-required"
            // label={label}
            size="small"
            sx={{
                width: width,
                height: height,
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: '#94a3b8', // Border color
                    },
                    '&:hover fieldset': {
                        borderColor: '#475569', // Border color on hover
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#1e293b', // Border color when focused
                    },
                    '& input': {
                        color: '#1e293b', // Text color
                        padding: '14px', // Adjust padding as needed
                    },
                }, 
                // '& .MuiInputLabel-root': {
                //     color: '#1f2937', // Label color
                // },
                // '& .MuiInputLabel-root': {
                //     color: '#6b7280', // Gray color for the label
                // },
                // '& .MuiInputLabel-root.Mui-focused': {
                //     color: '#be185d', // Label color when focused
                // },
                // '& .MuiFormControl-root': {
                //     position: 'relative', // Needed for absolute positioning of the label
                // }
            }}
            {...rest}
        />


    )
}
