import React, { useState } from 'react'
import product from "../../assets/product.png"
import { FormControlLabel, Switch } from '@mui/material'
import { styled } from '@mui/material/styles';


function Order() {
  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

  const [products, setProducts] = useState([
    {
      OrderId: 1,
      productImg: product,
      customerId: "34534",
      price: 699,
      purchasedDate: "22/09/2024",
      status: "Completed"

    },
    {
      OrderId: 1,
      productImg: product,
      customerId: "34534",
      price: 699,
      purchasedDate: "22/09/2024",
      status: "Completed"

    },
    {
      OrderId: 1,
      productImg: product,
      customerId: "34534",
      price: 699,
      purchasedDate: "22/09/2024",
      status: "Completed"

    },
    {
      OrderId: 1,
      productImg: product,
      customerId: "34534",
      price: 699,
      purchasedDate: "22/09/2024",
      status: "Completed"

    },
    {
      OrderId: 1,
      productImg: product,
      customerId: "34534",
      price: 699,
      purchasedDate: "22/09/2024",
      status: "Completed"

    },
    {
      OrderId: 1,
      productImg: product,
      customerId: "34534",
      price: 699,
      purchasedDate: "22/09/2024",
      status: "Completed"

    },
    {
      OrderId: 1,
      productImg: product,
      customerId: "34534",
      price: 699,
      purchasedDate: "22/09/2024",
      status: "Completed"

    },
    {
      OrderId: 1,
      productImg: product,
      customerId: "34534",
      price: 699,
      purchasedDate: "22/09/2024",
      status: "Completed"

    },
    {
      OrderId: 1,
      productImg: product,
      customerId: "34534",
      price: 699,
      purchasedDate: "22/09/2024",
      status: "Completed"

    },
    {
      OrderId: 1,
      productImg: product,
      customerId: "34534",
      price: 699,
      purchasedDate: "22/09/2024",
      status: "Completed"

    },
  ])
  return (
    <main className='main-container'>
      <div className='rounded-xl p-4 text-black' style={{ boxShadow: "0px 0px 4px 1px lightgray" }}>
        <div className='main-title mb-6'>
          <h3 className='text-black text-xl'>All Orders</h3>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 text-center">
            <thead className="bg-gray-200 ">
              <tr>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">Order Id</th>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">Product</th>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">customer Id</th>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">Price</th>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">Date Added</th>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">Status</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {
                products.length && products.map((item, index) =>
                  <tr key={index}>
                    <td className="px-6 py-2 whitespace-nowrap text-sm">{item.OrderId}</td>
                    <td className="px-6 py-2 whitespace-nowrap text-sm flex justify-center items-center">
                      <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap text-sm">{item.customerId}</td>
                    <td className="px-6 py-2 whitespace-nowrap text-sm">₹{item.price}</td>
                    <td className="px-6 py-2 whitespace-nowrap text-sm ">{item.purchasedDate}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm  text-green-600">
                      <div className='flex justify-evenly items-center gap-5'>
                        {item.status}
                        <FormControlLabel
                          control={<IOSSwitch className="" defaultChecked />}
                        />
                      </div>
                    </td>                 
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </main>
  )
}

export default Order