import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from "../assets/logo.png"
import { MdDashboard } from "react-icons/md";
import { MdCategory } from "react-icons/md";
import { IoIosCloseCircle } from "react-icons/io";
import { FaAddressBook } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaThList } from "react-icons/fa";





function SideBar({ openSidebarToggle, OpenSidebar }) {
    const location=useLocation()


    return (
        <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive" : ""} style={{ borderRight: "2px solid lightgray" }}>
            <div className='flex justify-center items-center w-full pt-3 bg-black'>
                <img src={logo} alt="" className='w-2/3 object-cover h-20' />
                <div className=' absolute top-0 right-0'>
                    {/* <span className='icon close_icon' onClick={OpenSidebar}>X</span> */}
                    <button className='text-2xl p-1 rounded-full text-white block md:hidden hover:scale-105 duration-300 transition-all' onClick={OpenSidebar}> <IoIosCloseCircle /></button>
                </div>
            </div>

            {/* style={{ height: `calc(100vh - 92px)` }} */}
            <div className='py-3 flex flex-col gap-y-2 bg-black' style={{ height: `calc(100vh - 92px)` }}>
                <Link to="/admin/dashboard" className='flex justify-start mx-1 rounded-lg  pl-4 p-[6px] items-center text-slate-200 texl:lg md:text-xl gap-2 hover:bg-pink-700 hover:text-white duration-500 transition-all'>
                    <MdDashboard className='text-lg  md:text-2xl' /> Dashboard
                </Link>
                <Link to="/admin/users" className='flex justify-start mx-1 rounded-xl  pl-4 p-[6px] items-center text-slate-200 text-sm  md:text-[16px] gap-2 hover:bg-pink-700 hover:text-white duration-500 transition-all'>
                    <FaUsers className='text-md  md:text-lg' /> Manage Users
                </Link>
                <Link to="/admin/orders" className='flex justify-start mx-1 rounded-xl  pl-4 p-[6px] items-center text-slate-200 text-sm  md:text-[16px] gap-2 hover:bg-pink-700 hover:text-white duration-500 transition-all'>
                    <FaThList className='text-sm  md:text-md' /> Manage Orders
                </Link>
                <Link to="/admin/category" className='flex justify-start mx-1 rounded-xl  pl-4 p-[6px] items-center text-slate-200 text-sm  md:text-[16px] gap-2 hover:bg-pink-700 hover:text-white duration-500 transition-all'>
                    <MdCategory className='text-md  md:text-lg' /> Add Category
                </Link>
                <Link to="/admin/books" className='flex justify-start mx-1 rounded-xl  pl-4 p-[6px] items-center text-slate-200 text-sm  md:text-[16px] gap-2 hover:bg-pink-700 hover:text-white duration-500 transition-all'>
                    <FaAddressBook className='text-sm  md:text-md' /> Add Book and Audio
                </Link>

            </div>
        </aside>
    )
}

export default SideBar
