import React, { useState } from 'react'
import Button from '@mui/material/Button';
import { IoMdAdd } from "react-icons/io";
import { Fab, IconButton, Switch, TableRow, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';






function Category() {

  const [categories, setCategories] = useState([
    { categoryId: 23, status: "active", category: "Fiction" },
    { categoryId: 23, status: "active", category: "Mystery & Thriller" },
    { categoryId: 23, status: "active", category: "Science Fiction & Fantasy" },
    { categoryId: 23, status: "active", category: "Biography & Memoir" },
    { categoryId: 23, status: "active", category: "Graphic Novels & Comics" },
    { categoryId: 23, status: "active", category: "Business & Economics" },
    { categoryId: 23, status: "active", category: "Science & Technology" },
    { categoryId: 23, status: "active", category: "Religion & Spirituality" },
  ])
  const [category, setCategory] = useState("")


  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

  function onSumbmitCategories(e) {
    // e.preventDefault();
    // if (!category) {
    //   alert("category field is required")
    // } else {
    //   setCategories((prev) => [...prev, category])
    //   setCategory("")

    // }
  }
  console.log(categories.length)

  return (
    <main className='main-container'>
      <div className='rounded-xl p-4 text-black' style={{ boxShadow: "0px 0px 4px 1px lightgray" }}>
        <form className='flex justify-center items-center flex-wrap md:flex-nowrap gap-6' onSubmit={onSumbmitCategories}>
          <input value={category} type="text" className='border-[2px] border-pink-300 outline-none text-gray-700 w-full md:w-3/4 py-2 pl-3 rounded-md hover:border-pink-700 duration-300' />
          <Button type='submit' className='w-[200px] md:w-1/4 flex font-extrabold justify-center items-center gap-2' variant='contained' sx={{ padding: "8px", backgroundColor: "#db2777" }}>
            <IoMdAdd className='text-white text-lg' /> Add Category </Button>
        </form>

        <div className="overflow-x-auto max-h-[400px] overflow-y-scroll " id='tableData'>
          <table className="min-w-full divide-y divide-gray-200 text-center mt-5">
            <thead className="bg-gray-100 sticky top-0 z-10">
              <tr>
                <th className="px-4 py-4 text-xs md:text-sm font-bold uppercase tracking-wider">Category Name</th>
                <th className="px-4 py-4 text-xs md:text-sm font-bold uppercase tracking-wider">Category Id</th>
                <th className="px-4 py-4 text-xs md:text-sm font-bold uppercase tracking-wider">Status</th>
                <th className="px-4 py-4 text-xs md:text-sm font-bold uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {
                categories.length && categories.map((item, index) =>
                  <tr key={index}>
                    <td className="px-4 py-2 text-xs md:text-sm">{item.categoryId}</td>
                    <td className="px-4 py-2 text-xs md:text-sm">{item.category}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm  text-green-600">
                      <div className='flex justify-evenly items-center gap-5'>
                        {item.status}
                        <FormControlLabel
                          control={<IOSSwitch className="" defaultChecked />}
                        />
                      </div>
                    </td>
                    <td className="px-4 py-2 text-xs md:text-sm">
                      <div className="flex gap-5 items-center justify-evenly">
                        <Tooltip title='Edit'>
                          <IconButton onClick={() => { alert(`Item edited ${item.categoryId}`); }}>
                              <EditIcon/>
                          </IconButton>
                        </Tooltip>

                        <Tooltip title='Delete'>
                          <IconButton onClick={() => { alert(`Item deleted ${item.categoryId}`); }}>
                              <DeleteIcon/>
                          </IconButton>
                        </Tooltip>
                      </div>
                    </td>

                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </main>
  )
}

export default Category