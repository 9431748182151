import { FormControlLabel, IconButton, Switch, Tooltip } from '@mui/material';
import React, { useState } from 'react'
import { styled } from '@mui/material/styles';




function User() {
  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

  const [users, setUsers] = useState([
    {
      id: 101,
      name: "Iron Man",
      phone: 6789056789,
      email: "iron@123gmail.com",
      status: "active"
    },
    {
      id: 102,
      name: "Iron Man",
      phone: 6789056789,
      email: "iron@123gmail.com",
      status: "active"
    },
    {
      id: 103,
      name: "Iron Man",
      phone: 6789056789,
      email: "iron@123gmail.com",
      status: "active"
    },
    {
      id: 104,
      name: "Iron Man",
      phone: 6789056789,
      email: "iron@123gmail.com",
      status: "active"
    },
    {
      id: 105,
      name: "Iron Man",
      phone: 6789056789,
      email: "iron@123gmail.com",
      status: "active"
    },
    {
      id: 106,
      name: "Iron Man",
      phone: 6789056789,
      email: "iron@123gmail.com",
      status: "active"
    },
    {
      id: 107,
      name: "Iron Man",
      phone: 6789056789,
      email: "iron@123gmail.com",
      status: "active"
    },
    {
      id: 108,
      name: "Iron Man",
      phone: 6789056789,
      email: "iron@123gmail.com",
      status: "active"
    },
    {
      id: 109,
      name: "Iron Man",
      phone: 6789056789,
      email: "iron@123gmail.com",
      status: "active"
    },
    {
      id: 110,
      name: "Iron Man",
      phone: 6789056789,
      email: "iron@123gmail.com",
      status: "active"
    },
    {
      id: 111,
      name: "Iron Man",
      phone: 6789056789,
      email: "iron@123gmail.com",
      status: "active"
    },
    {
      id: 112,
      name: "Iron Man",
      phone: 6789056789,
      email: "iron@123gmail.com",
      status: "active"
    },
  ]
  )
  return (
    <main className='main-container'>
      <div className='rounded-xl p-4 text-black' style={{ boxShadow: "0px 0px 4px 1px lightgray" }}>
        <div className='main-title mb-6'>
          <h3 className='text-black text-xl'>User</h3>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 text-center">
            <thead className="bg-gray-200 ">
              <tr>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">User Id</th>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">User Name</th>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">Phone No</th>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">Status</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {
                users.map((user, index) => {
                  return (<>
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap">{user.id}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{user.phone}</td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm  text-green-600">
                      <div className='flex justify-evenly items-center gap-5'>
                        {user.status}
                        <FormControlLabel
                          control={<IOSSwitch className="" defaultChecked />}
                        />
                      </div>
                    </td>                      
                    </tr>
                  </>)
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </main>
  )
}

export default User