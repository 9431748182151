import React, { useState, useEffect, useRef } from 'react'
import { BsJustify } from 'react-icons/bs'
import adminLogo from "../assets/akshay.jpeg"
import { useNavigate } from 'react-router-dom'

function Header({ OpenSidebar }) {
  const [show, setShow] = useState(false);
  const navigate = useNavigate()
  const dropdownRef = useRef(null); // Reference to the dropdown

  // This effect listens for clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false); // Close dropdown if clicked outside
      }
    };

    // Add event listener when show is true
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      // Clean up the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  return (
    <header className='header h-20 flex items-center justify-between md:justify-end px-8 shadow-md bg-slate-100'>
      <div className='block md:hidden p-2 hover:bg-slate-200 rounded-lg duration-300 cursor-pointer' onClick={OpenSidebar}>
        <BsJustify className='icon' />
      </div>
      <div className='flex justify-end '>
        <div className="relative" ref={dropdownRef}>
          <div
            onClick={() => setShow(!show)} // Toggle dropdown on click
          >
            <img src={adminLogo} alt="admin" className='h-16 w-16 object-cover rounded-full cursor-pointer' />
          </div>
          {show ? (
            <div
              className="w-40 border bg-slate-100 shadow-md absolute top-14 right-0 z-50 px-4 py-3 rounded-b-lg flex flex-col gap-2 duration-300"
            >
              <div className='flex flex-col border-b border-slate-300 pb-3'>
                <b className='text-sm'>Admin</b>
                <span className='text-xs -mt-1 text-slate-500'>admin@admin.com</span>
              </div>
              <div className='border-b border-slate-300 pb-3 flex flex-col'>
                <button
                  onClick={() => navigate("/edit-profile")}
                  className="text-sm flex justify-start mb-1"
                >
                  Edit Profile
                </button>
                <button
                  onClick={() => navigate("/admin/change-password")}
                  className="text-sm flex justify-start"
                >
                  Change Password
                </button>
              </div>
              <button
                onClick={() => navigate("/edit-profile")}
                className="text-sm pb-3 text-red-600 flex justify-start"
              >
                Logout
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </header>
  )
}

export default Header;
