import React from 'react'
import { Outlet } from 'react-router-dom'

function LayoutAdmin() {
  return (
    <div className='main'>
        <Outlet />
    </div>
  )
}

export default LayoutAdmin