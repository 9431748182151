import React from 'react'
import { MdCategory } from "react-icons/md";
import { FaAddressBook } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaThList } from "react-icons/fa";
import product from "../../assets/product.png"




function Dashboard() {
  return (
    <main className='main-container'>
      <div className='flex flex-wrap justify-center md:justify-between items-center gap-5 text-black'>
        {/* Each user block */}
        <div className='flex justify-center flex-col rounded-lg bg-pink-600 items-center border w-[45%] md:w-[45%] lg:w-[22%] p-4'>
          <p className='p-3 mb-1 rounded-full bg-white'><FaUsers className='text-lg'/></p>
          <p className='text-white text-[22px] font-bold'>18k</p>
          <span className='text-white -mt-1 text-sm '>Total Users</span>
        </div>
        <div className='flex justify-center flex-col rounded-lg bg-pink-600 items-center border w-[45%] md:w-[45%] lg:w-[22%] p-4'>
          <p className='p-3 mb-1 rounded-full bg-white'><FaThList className='text-lg'/></p>
          <p className='text-white text-[22px] font-bold'>34k</p>
          <span className='text-white -mt-1 text-sm '>Total Orders</span>
        </div>
        <div className='flex justify-center flex-col rounded-lg bg-pink-600 items-center border w-[45%] md:w-[45%] lg:w-[22%] p-4'>
          <p className='p-3 mb-1 rounded-full bg-white'><MdCategory className='text-lg'/></p>
          <p className='text-white text-[22px] font-bold'>456</p>
          <span className='text-white -mt-1 text-sm '>Total Category</span>
        </div>
        <div className='flex justify-center flex-col rounded-lg bg-pink-600 items-center border w-[45%] md:w-[45%] lg:w-[22%] p-4'>
          <p className='p-3 mb-1 rounded-full bg-white'><FaAddressBook className='text-lg'/></p>
          <p className='text-white text-[22px] font-bold'>3354</p>
          <span className='text-white -mt-1 text-sm '>Total Books</span>
        </div>
      </div>

      <div className=' rounded-xl p-4 text-black mt-5' style={{ boxShadow: "0px 0px 4px 1px lightgray" }}>
        <div className='main-title mb-5'>
          <h3 className='text-black text-xl'>All Orders</h3>
        </div>

        <div className="overflow-x-auto max-h-[600px] overflow-y-scroll " id='tableData'>
          <table className="min-w-full divide-y divide-gray-200 text-center scroll">
            <thead className="bg-gray-200 sticky top-0 z-10">
              <tr>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">Order Id</th>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">Product</th>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">customer Id</th>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">Price</th>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">Date Added</th>
                <th className="px-6 py-3 text-sm font-bold uppercase tracking-wider">Status</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-6 py-2 whitespace-nowrap text-sm">5656</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">dfjkdjfkdjfk4k545</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">₹899</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm ">11/09/2024</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-green-600">Completed</td>
              </tr>
              <tr>
                <td className="px-6 py-2 whitespace-nowrap text-sm">5656</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">dfjkdjfkdjfk4k545</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">₹899</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm ">11/09/2024</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-green-600">Completed</td>
              </tr>
              <tr>
                <td className="px-6 py-2 whitespace-nowrap text-sm">5656</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">dfjkdjfkdjfk4k545</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">₹899</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm ">11/09/2024</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-green-600">Completed</td>
              </tr>
              <tr>
                <td className="px-6 py-2 whitespace-nowrap text-sm">5656</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">dfjkdjfkdjfk4k545</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">₹899</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm ">11/09/2024</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-green-600">Completed</td>
              </tr>
              <tr>
                <td className="px-6 py-2 whitespace-nowrap text-sm">5656</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">dfjkdjfkdjfk4k545</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">₹899</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm ">11/09/2024</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-green-600">Completed</td>
              </tr>
              <tr>
                <td className="px-6 py-2 whitespace-nowrap text-sm">5656</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">dfjkdjfkdjfk4k545</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">₹899</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm ">11/09/2024</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-green-600">Completed</td>
              </tr>
              <tr>
                <td className="px-6 py-2 whitespace-nowrap text-sm">5656</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">dfjkdjfkdjfk4k545</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">₹899</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm ">11/09/2024</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-green-600">Completed</td>
              </tr>
              <tr>
                <td className="px-6 py-2 whitespace-nowrap text-sm">5656</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">dfjkdjfkdjfk4k545</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">₹899</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm ">11/09/2024</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-green-600">Completed</td>
              </tr>
              <tr>
                <td className="px-6 py-2 whitespace-nowrap text-sm">5656</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">dfjkdjfkdjfk4k545</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">₹899</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm ">11/09/2024</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-green-600">Completed</td>
              </tr>
              <tr>
                <td className="px-6 py-2 whitespace-nowrap text-sm">5656</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">dfjkdjfkdjfk4k545</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">₹899</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm ">11/09/2024</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-green-600">Completed</td>
              </tr>
              <tr>
                <td className="px-6 py-2 whitespace-nowrap text-sm">5656</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">dfjkdjfkdjfk4k545</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">₹899</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm ">11/09/2024</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-red-600">Cancelled</td>
              </tr>
              <tr>
                <td className="px-6 py-2 whitespace-nowrap text-sm">5656</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">dfjkdjfkdjfk4k545</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">₹899</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm ">11/09/2024</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-green-600">Completed</td>
              </tr>
              <tr>
                <td className="px-6 py-2 whitespace-nowrap text-sm">5656</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">dfjkdjfkdjfk4k545</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">₹899</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm ">11/09/2024</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-green-600">Completed</td>
              </tr>
              <tr>
                <td className="px-6 py-2 whitespace-nowrap text-sm">5656</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">dfjkdjfkdjfk4k545</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">₹899</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm ">11/09/2024</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-red-600">Cancelled</td>
              </tr>
              <tr>
                <td className="px-6 py-2 whitespace-nowrap text-sm">5656</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  <img src={product} alt="product" className='h-16 w-16 rounded-full border p-2 object-cover' />
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">dfjkdjfkdjfk4k545</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">₹899</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm ">11/09/2024</td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-green-600">Completed</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
  )

}

export default Dashboard