import { Outlet } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import SideBar from './components/SideBar';
import { useState } from 'react';

const App = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  
  return (
    <div className='grid-container bg-slate-50'>
      <Header OpenSidebar={OpenSidebar} />
      <SideBar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
      <Outlet />
    </div>
  );
};


export default App;
