import React, { useState } from 'react'
import TextInput from '../../components/TextInput'
import { Autocomplete, Button, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import imageToBase64 from "../../components/imageToBase64.js";
import file from "../../assets/pdf-file.png"
import check from "../../assets/check.png"
import { IoIosCloseCircle, IoMdAdd } from "react-icons/io";
import gallery from "../../assets/gallery.png"
import playlist from "../../assets/playlist.png"
import musicgif from "../../assets/musical.png"
import { useNavigate } from 'react-router-dom';



function AddBooks() {
  const categoryList = [
    {
      id: "1",
      name: "Fiction"
    },
    {
      id: "2",
      name: "Mystery & Thriller"
    },
    {
      id: "3",
      name: "Science Fiction & Fantasy"
    },
  ]

  const [books, setBooks,] = useState({
    bookTitle: undefined,
    offerPrice: undefined,
    booksPdf: undefined,
    price: undefined,
    freeAndPaid: undefined,
    bookThumbnail: undefined,
    bookAudio: undefined

  })
  const [booksPdfToggle, setBooksPdfToggle] = useState(false)
  const [booksThumbnailToggle, setBooksThumbnailToggle] = useState(false)
  const [booksAudioToggle, setBooksAudioToggle] = useState(false)
  const navigate = useNavigate();


  async function handlePdfImg(e) {
    const file = e.target.files[0];
    if (!file) {
      console.error("No file selected or file type is incorrect.");
      return;
    }
    try {
      const bookpdf = await imageToBase64(file);
      if (bookpdf) {
        setBooksPdfToggle(true);
        setBooks((prev) => ({ ...prev, booksPdf: bookpdf }));
      }
    } catch (error) {
      console.error("Error converting image to base64:", error);
    }
  }
  function bookTogglbtn() {
    setBooksPdfToggle(false)
    setBooks((prev) => {
      return { ...prev, booksPdf: undefined }
    })

  }



  //handle BooksThumbnail
  async function handleThumbnailImg(e) {
    const file = e.target.files[0];
    if (!file) {
      console.error("No file selected or file type is incorrect.");
      return;
    }
    try {
      const thumbnail = await imageToBase64(file);
      if (thumbnail) {
        setBooksThumbnailToggle(true);
        setBooks((prev) => ({ ...prev, bookThumbnail: thumbnail }));
      }
    } catch (error) {
      console.error("Error converting image to base64:", error);
    }
  }
  function bookThumbnailTogglbtn() {
    setBooksThumbnailToggle(false)
    setBooks((prev) => {
      return { ...prev, bookThumbnail: undefined }
    })

  }
  // close handle BooksThumbnail


  //handle BooksThumbnail
  async function handleAudioImg(e) {
    const file = e.target.files[0];
    if (!file) {
      console.error("No file selected or file type is incorrect.");
      return;
    }
    try {
      const Audio = await imageToBase64(file);
      if (Audio) {
        setBooksAudioToggle(true);
        setBooks((prev) => ({ ...prev, bookAudio: Audio }));
      }
    } catch (error) {
      console.error("Error converting image to base64:", error);
    }
  }
  function bookAudioTogglbtn() {
    setBooksAudioToggle(false)
    setBooks((prev) => {
      return { ...prev, bookAudio: undefined }
    })

  }
  // close handle BooksThumbnail



  return (
    <main className='main-container'>
      <div className='rounded-xl p-4 text-black' style={{ boxShadow: "0px 0px 4px 1px lightgray" }}>
        <div className='mb-10 flex justify-center'>
          <h3 className='text-black text-xl font-bold'>Add favorite Books</h3>
        </div>

        <form>
          <div className='flex flex-col'>
            <label className='text-sm'>Book title<span className='text-red-600 text-lg ml-1'>*</span></label>
            <TextInput className="w-full md:w-1/2 mt-1" value={books.bookTitle} onChange={(event)=>setBooks((prevData)=>({...prevData,bookTitle:event.target.value}))}/>
          </div>


          <div className='flex flex-col mt-8'>
            <label className='text-sm'>Choose Category<span className='text-red-600 text-lg ml-1'>*</span></label>
            <Autocomplete
              id="tags-outlined"
              options={categoryList}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              className="w-full md:w-1/2 mt-1"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#94a3b8', // Border color
                  },
                  '&:hover fieldset': {
                    borderColor: '#475569', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1e293b', // Border color when focused
                  },
                  '& input': {
                    color: '#1e293b', // Text color
                    padding: '14px', // Padding for input field
                  },
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                />
              )}
            />
          </div>

          {/* //Book thumbnail  */}
          <div className='mt-8'>
            <label className='text-sm mb-1'>Book thumbnail<span className='text-red-600 text-lg ml-1'>*</span></label>
            <div className=" relative w-full md:w-[26%] p-4  h-64 border-[1px] border-dashed border-gray-400 flex-col rounded-md flex justify-center items-center">
              {
                books.bookThumbnail ?
                  (<>
                    <img src={books.bookThumbnail} alt="" className='object-cover w-full h-full' />
                  </>
                  ) :
                  (<>
              <img src={gallery} alt="" className='h-12 w-14 object-cover' />
              <p className="text-gray-600 text-[13px] w-[80%] text-center mt-1">
                Click to upload the image for the best book thumbnail.
              </p>

              <input
                type="file"
                className="w-full h-full opacity-0 absolute cursor-pointer"
                onChange={handleThumbnailImg}
                accept="image/*"
              />
              </>
                  )
              }
              {booksThumbnailToggle && <div className='absolute -top-4 -right-5 cursor-pointer' onClick={bookThumbnailTogglbtn}> <IoIosCloseCircle className='text-2xl' /></div>
              }
            </div>
          </div>

          {/* Book PDF  */}
          <div className='mt-8'>
            <label className='text-sm mb-1'>Upload Book<span className='text-red-600 text-lg ml-1'>*</span></label>
            <div className=" relative w-full md:w-[48.5%] h-24 border-[1px] border-dashed border-gray-400 flex-col rounded-md flex justify-center items-center">
              {
                books.booksPdf ?
                  (<>
                    <img src={check} alt="" className='h-8 w-8 object-cover' />
                    <p className="text-gray-600 text-[13px] mt-1">
                      PDF Uploaded Successfully !
                    </p>
                  </>
                  ) :
                  (<>
              <img src={file} alt="" className='h-12 w-14 object-cover' />
              <p className="text-gray-600 text-[13px] mt-1">
                Click to upload the book in PDF format
              </p>
              <input
                type="file"
                className="w-full h-24 opacity-0 absolute cursor-pointer"
                onChange={handlePdfImg}
                accept="application/pdf"
              />

              </>
                  )
              }
              {booksPdfToggle && <div className='absolute -top-4 -right-5 cursor-pointer' onClick={bookTogglbtn}> <IoIosCloseCircle className='text-2xl' /></div>
              }
            </div>
          </div>

          {/* // Book Audio */}
          <div className='mt-8 mb-1'>
            <label className='text-sm mb-1'>Upload Audio</label>
            <div className=" relative w-full md:w-[48.5%] h-24 border-[1px] border-dashed border-gray-400 flex-col rounded-md flex justify-center items-center">
              {
                books.bookAudio ?
                  (<>
                    <img src={musicgif} alt="" className='h-14 w-14 object-cover' />
                    <p className="text-gray-600 text-[13px] mt-1">
                      Book Audio Uploaded Successfully !
                    </p>
                  </>
                  ) :
                  (<>
              <img src={playlist} alt="" className='h-12 w-14 object-cover' />
              <p className="text-gray-600 text-[13px] mt-1">
                Click to upload the book Audio
              </p>
              <input
                type="file"
                className="w-full h-24 opacity-0 absolute cursor-pointer"
                onChange={handleAudioImg}
                accept="audio/*"
              />

              </>
                  )
              }
              {booksAudioToggle && <div className='absolute -top-4 -right-5 cursor-pointer' onClick={bookAudioTogglbtn}> <IoIosCloseCircle className='text-2xl' /></div>
              }
            </div>
          </div>

          {/* Radio button  */}
          <div className='flex flex-col mt-8'>
            <label className='text-sm'>Select Free or Paid<span className='text-red-600 text-lg ml-1'>*</span></label>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event) => setBooks((prevData) => ({ ...prevData, freeAndPaid: event.target.value }))}>
              <FormControlLabel value="FREE" control={<Radio sx={{ '&.Mui-checked': { color: "#db2777",font:"14px" } }} />} label="Free" />
              <FormControlLabel value="PAID" control={<Radio sx={{ '&.Mui-checked': { color: "#db2777",font:"14px"} }} />} label="Paid" />
            </RadioGroup>
          </div>
          {
            (books.freeAndPaid === "PAID") && <>
              <div className='flex flex-col mt-8'>
                <label className='text-sm'>Price<span className='text-red-600 text-lg ml-1'>*</span></label>
                <TextInput className="w-full md:w-1/2 mt-1" value={books.price} onChange={(event)=>setBooks((prevData)=>({...prevData,price:event.target.value}))}/>
              </div>
              <div className='flex flex-col mt-8'>
                <label className='text-sm'>Offer Price<span className='text-red-600 text-lg ml-1'>*</span></label>
                <TextInput className="w-full md:w-1/2 mt-1" value={books.offerPrice} onChange={(event)=>setBooks((prevData)=>({...prevData,offerPrice:event.target.value}))}/>
              </div>
            </>
          }
          <div className='mt-8'>
            <Button onClick={() => navigate("/admin/books")} type='submit' className='w-full md:w-1/2 flex font-extrabold justify-center items-center gap-2' variant='contained' sx={{ padding: "8px", backgroundColor: "#db2777" }}>
              <IoMdAdd className='text-white text-lg' /> Add Books </Button>
          </div>
        </form>
      </div>
    </main>
  )
}

export default AddBooks