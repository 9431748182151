import { createBrowserRouter } from "react-router-dom"
import App from "../App"
import PageNotFound from "../pages/PageNotFound";
import Dashboard from "../pages/Admin/Dashboard";
import Login from "../pages/authAdmin/Login"
import ForgetPassword from "../pages/authAdmin/ForgetPassword";
import LayoutAdmin from "../pages/authAdmin/LayoutAdmin";
import User from "../pages/Admin/User";
import Order from "../pages/Admin/Order"
import Category from "../pages/Admin/Category"
import BookAndAudio from "../pages/Admin/BookAndAudio"
import AddBooks from "../pages/Admin/AddBooks";
import SeeDetails from "../pages/Admin/SeeDetails";
import ChangePassword from "../pages/Admin/ChangePassword";

const router = createBrowserRouter([
    {
        path: "/auth-admin",
        element: <LayoutAdmin />,
        children: [
            {
                path: "login",
                element: <Login />
            },
            {
                path: "forget-password",
                element: <ForgetPassword />
            },
        ]
    },
    {
        path: "/admin",
        element: <App />,
        children: [
            {
                path: "Dashboard",
                element: <Dashboard />
            },
            {
                path: "users",
                element: <User />
            },
            {
                path: "orders",
                element: <Order />
            },
            {
                path: "category",
                element: <Category />
            },
            {
                path: "books",
                element: <BookAndAudio />,
            },
            {
                path: "add-books",
                element: <AddBooks />
            },
            {
                path: "see-details/:id",
                element: <SeeDetails />
            },
            {
                path: "change-password",
                element: <ChangePassword />
            }

        ]
    },
    {
        path: "/*",
        element: <PageNotFound />
    }
])

export default router;

