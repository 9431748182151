import React, { useState } from 'react'
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ramBook from "../../assets/ram2Books.png"
import { IconButton, Switch, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import VisibilityIcon from '@mui/icons-material/Visibility';





function BookAndAudio() {

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

  const [books, setBooks] = useState([
    {
      bookImg: "",
      book_title: "श्रीरामचरितमानस Ramcharitmanas",
      category: "Scripture",
      price: "699",
      status: "published",
      bookDescription: `Ramcharitmanas (Devanagari: रामचरितमानस rāmacaritamānasa), is an epic poem in the Awadhi language, composed by the 16th-century Indian bhakti poet Tulsidas. It has many inspirations, the primary being the Ramayana of Valmiki.
      This work is also called, in popular parlance, Tulsi Ramayana, Tulsikrit Ramayana, Tulsidas Ramayana or simply Manas. The word Ramcharitmanas literally means "Lake of the deeds of Rama".[1] It is considered one of the greatest works of Hindu literature. The work has variously been acclaimed as "the living sum of Indian culture", "the tallest tree in the magic garden of medieval Indian poetry", "the greatest book of all devotional literature" and "the best and most trustworthy guide to the popular living faith of the Indian people" Part of a series on Hindu scriptures and texts ShrutiSmriti List
      Vedas Upanishads Other scriptures Related Hindu texts Vedangas Puranas Itihasa Sangam literature Shastras and sutras Timeline vte
      Tulsidas was a great scholar of Sanskrit, but due to limited accessibility of the language, he chose to write it in the vernacular, Awadhi, making his work more accessible to the general public.[3] Tradition has it that Tulsidas had to face much criticism from the Sanskrit scholars of Varanasi for being a vernacular poet. However, Tulsidas remained steadfast in his resolve to simplify the knowledge contained in the Vedas, the Upanishads and the Puranas to the common people. Subsequently, his work was widely accepted.`
    },
    {
      bookImg: "",
      book_title: "श्रीरामचरितमानस Ramcharitmanas",
      category: "Scripture",
      price: "699",
      status: "published",
      bookDescription: `Ramcharitmanas (Devanagari: रामचरितमानस rāmacaritamānasa), is an epic poem in the Awadhi language, composed by the 16th-century Indian bhakti poet Tulsidas. It has many inspirations, the primary being the Ramayana of Valmiki.
      This work is also called, in popular parlance, Tulsi Ramayana, Tulsikrit Ramayana, Tulsidas Ramayana or simply Manas. The word Ramcharitmanas literally means "Lake of the deeds of Rama".[1] It is considered one of the greatest works of Hindu literature. The work has variously been acclaimed as "the living sum of Indian culture", "the tallest tree in the magic garden of medieval Indian poetry", "the greatest book of all devotional literature" and "the best and most trustworthy guide to the popular living faith of the Indian people" Part of a series on Hindu scriptures and texts ShrutiSmriti List
      Vedas Upanishads Other scriptures Related Hindu texts Vedangas Puranas Itihasa Sangam literature Shastras and sutras Timeline vte
      Tulsidas was a great scholar of Sanskrit, but due to limited accessibility of the language, he chose to write it in the vernacular, Awadhi, making his work more accessible to the general public.[3] Tradition has it that Tulsidas had to face much criticism from the Sanskrit scholars of Varanasi for being a vernacular poet. However, Tulsidas remained steadfast in his resolve to simplify the knowledge contained in the Vedas, the Upanishads and the Puranas to the common people. Subsequently, his work was widely accepted.`
    },
    {
      bookImg: "",
      book_title: "श्रीरामचरितमानस Ramcharitmanas",
      category: "Scripture",
      price: "699",
      status: "published",
      bookDescription: `Ramcharitmanas (Devanagari: रामचरितमानस rāmacaritamānasa), is an epic poem in the Awadhi language, composed by the 16th-century Indian bhakti poet Tulsidas. It has many inspirations, the primary being the Ramayana of Valmiki.
      This work is also called, in popular parlance, Tulsi Ramayana, Tulsikrit Ramayana, Tulsidas Ramayana or simply Manas. The word Ramcharitmanas literally means "Lake of the deeds of Rama".[1] It is considered one of the greatest works of Hindu literature. The work has variously been acclaimed as "the living sum of Indian culture", "the tallest tree in the magic garden of medieval Indian poetry", "the greatest book of all devotional literature" and "the best and most trustworthy guide to the popular living faith of the Indian people" Part of a series on Hindu scriptures and texts ShrutiSmriti List
      Vedas Upanishads Other scriptures Related Hindu texts Vedangas Puranas Itihasa Sangam literature Shastras and sutras Timeline vte
      Tulsidas was a great scholar of Sanskrit, but due to limited accessibility of the language, he chose to write it in the vernacular, Awadhi, making his work more accessible to the general public.[3] Tradition has it that Tulsidas had to face much criticism from the Sanskrit scholars of Varanasi for being a vernacular poet. However, Tulsidas remained steadfast in his resolve to simplify the knowledge contained in the Vedas, the Upanishads and the Puranas to the common people. Subsequently, his work was widely accepted.`
    },
    {
      bookImg: "",
      book_title: "श्रीरामचरितमानस Ramcharitmanas",
      category: "Scripture",
      price: "699",
      status: "published",
      bookDescription: `Ramcharitmanas (Devanagari: रामचरितमानस rāmacaritamānasa), is an epic poem in the Awadhi language, composed by the 16th-century Indian bhakti poet Tulsidas. It has many inspirations, the primary being the Ramayana of Valmiki.
      This work is also called, in popular parlance, Tulsi Ramayana, Tulsikrit Ramayana, Tulsidas Ramayana or simply Manas. The word Ramcharitmanas literally means "Lake of the deeds of Rama".[1] It is considered one of the greatest works of Hindu literature. The work has variously been acclaimed as "the living sum of Indian culture", "the tallest tree in the magic garden of medieval Indian poetry", "the greatest book of all devotional literature" and "the best and most trustworthy guide to the popular living faith of the Indian people" Part of a series on Hindu scriptures and texts ShrutiSmriti List
      Vedas Upanishads Other scriptures Related Hindu texts Vedangas Puranas Itihasa Sangam literature Shastras and sutras Timeline vte
      Tulsidas was a great scholar of Sanskrit, but due to limited accessibility of the language, he chose to write it in the vernacular, Awadhi, making his work more accessible to the general public.[3] Tradition has it that Tulsidas had to face much criticism from the Sanskrit scholars of Varanasi for being a vernacular poet. However, Tulsidas remained steadfast in his resolve to simplify the knowledge contained in the Vedas, the Upanishads and the Puranas to the common people. Subsequently, his work was widely accepted.`
    },
    {
      bookImg: "",
      book_title: "श्रीरामचरितमानस Ramcharitmanas",
      category: "Scripture",
      price: "699",
      status: "published",
      bookDescription: `Ramcharitmanas (Devanagari: रामचरितमानस rāmacaritamānasa), is an epic poem in the Awadhi language, composed by the 16th-century Indian bhakti poet Tulsidas. It has many inspirations, the primary being the Ramayana of Valmiki.
      This work is also called, in popular parlance, Tulsi Ramayana, Tulsikrit Ramayana, Tulsidas Ramayana or simply Manas. The word Ramcharitmanas literally means "Lake of the deeds of Rama".[1] It is considered one of the greatest works of Hindu literature. The work has variously been acclaimed as "the living sum of Indian culture", "the tallest tree in the magic garden of medieval Indian poetry", "the greatest book of all devotional literature" and "the best and most trustworthy guide to the popular living faith of the Indian people" Part of a series on Hindu scriptures and texts ShrutiSmriti List
      Vedas Upanishads Other scriptures Related Hindu texts Vedangas Puranas Itihasa Sangam literature Shastras and sutras Timeline vte
      Tulsidas was a great scholar of Sanskrit, but due to limited accessibility of the language, he chose to write it in the vernacular, Awadhi, making his work more accessible to the general public.[3] Tradition has it that Tulsidas had to face much criticism from the Sanskrit scholars of Varanasi for being a vernacular poet. However, Tulsidas remained steadfast in his resolve to simplify the knowledge contained in the Vedas, the Upanishads and the Puranas to the common people. Subsequently, his work was widely accepted.`
    },
    {
      bookImg: "",
      book_title: "श्रीरामचरितमानस Ramcharitmanas",
      category: "Scripture",
      price: "699",
      status: "published",
      bookDescription: `Ramcharitmanas (Devanagari: रामचरितमानस rāmacaritamānasa), is an epic poem in the Awadhi language, composed by the 16th-century Indian bhakti poet Tulsidas. It has many inspirations, the primary being the Ramayana of Valmiki.
      This work is also called, in popular parlance, Tulsi Ramayana, Tulsikrit Ramayana, Tulsidas Ramayana or simply Manas. The word Ramcharitmanas literally means "Lake of the deeds of Rama".[1] It is considered one of the greatest works of Hindu literature. The work has variously been acclaimed as "the living sum of Indian culture", "the tallest tree in the magic garden of medieval Indian poetry", "the greatest book of all devotional literature" and "the best and most trustworthy guide to the popular living faith of the Indian people" Part of a series on Hindu scriptures and texts ShrutiSmriti List
      Vedas Upanishads Other scriptures Related Hindu texts Vedangas Puranas Itihasa Sangam literature Shastras and sutras Timeline vte
      Tulsidas was a great scholar of Sanskrit, but due to limited accessibility of the language, he chose to write it in the vernacular, Awadhi, making his work more accessible to the general public.[3] Tradition has it that Tulsidas had to face much criticism from the Sanskrit scholars of Varanasi for being a vernacular poet. However, Tulsidas remained steadfast in his resolve to simplify the knowledge contained in the Vedas, the Upanishads and the Puranas to the common people. Subsequently, his work was widely accepted.`
    },
    {
      bookImg: "",
      book_title: "श्रीरामचरितमानस Ramcharitmanas",
      category: "Scripture",
      price: "699",
      status: "published",
      bookDescription: `Ramcharitmanas (Devanagari: रामचरितमानस rāmacaritamānasa), is an epic poem in the Awadhi language, composed by the 16th-century Indian bhakti poet Tulsidas. It has many inspirations, the primary being the Ramayana of Valmiki.
      This work is also called, in popular parlance, Tulsi Ramayana, Tulsikrit Ramayana, Tulsidas Ramayana or simply Manas. The word Ramcharitmanas literally means "Lake of the deeds of Rama".[1] It is considered one of the greatest works of Hindu literature. The work has variously been acclaimed as "the living sum of Indian culture", "the tallest tree in the magic garden of medieval Indian poetry", "the greatest book of all devotional literature" and "the best and most trustworthy guide to the popular living faith of the Indian people" Part of a series on Hindu scriptures and texts ShrutiSmriti List
      Vedas Upanishads Other scriptures Related Hindu texts Vedangas Puranas Itihasa Sangam literature Shastras and sutras Timeline vte
      Tulsidas was a great scholar of Sanskrit, but due to limited accessibility of the language, he chose to write it in the vernacular, Awadhi, making his work more accessible to the general public.[3] Tradition has it that Tulsidas had to face much criticism from the Sanskrit scholars of Varanasi for being a vernacular poet. However, Tulsidas remained steadfast in his resolve to simplify the knowledge contained in the Vedas, the Upanishads and the Puranas to the common people. Subsequently, his work was widely accepted.`
    },
    {
      bookImg: "",
      book_title: "श्रीरामचरितमानस Ramcharitmanas",
      category: "Scripture",
      price: "699",
      status: "published",
      bookDescription: `Ramcharitmanas (Devanagari: रामचरितमानस rāmacaritamānasa), is an epic poem in the Awadhi language, composed by the 16th-century Indian bhakti poet Tulsidas. It has many inspirations, the primary being the Ramayana of Valmiki.
      This work is also called, in popular parlance, Tulsi Ramayana, Tulsikrit Ramayana, Tulsidas Ramayana or simply Manas. The word Ramcharitmanas literally means "Lake of the deeds of Rama".[1] It is considered one of the greatest works of Hindu literature. The work has variously been acclaimed as "the living sum of Indian culture", "the tallest tree in the magic garden of medieval Indian poetry", "the greatest book of all devotional literature" and "the best and most trustworthy guide to the popular living faith of the Indian people" Part of a series on Hindu scriptures and texts ShrutiSmriti List
      Vedas Upanishads Other scriptures Related Hindu texts Vedangas Puranas Itihasa Sangam literature Shastras and sutras Timeline vte
      Tulsidas was a great scholar of Sanskrit, but due to limited accessibility of the language, he chose to write it in the vernacular, Awadhi, making his work more accessible to the general public.[3] Tradition has it that Tulsidas had to face much criticism from the Sanskrit scholars of Varanasi for being a vernacular poet. However, Tulsidas remained steadfast in his resolve to simplify the knowledge contained in the Vedas, the Upanishads and the Puranas to the common people. Subsequently, his work was widely accepted.`
    },
    {
      bookImg: "",
      book_title: "श्रीरामचरितमानस Ramcharitmanas",
      category: "Scripture",
      price: "699",
      status: "published",
      bookDescription: `Ramcharitmanas (Devanagari: रामचरितमानस rāmacaritamānasa), is an epic poem in the Awadhi language, composed by the 16th-century Indian bhakti poet Tulsidas. It has many inspirations, the primary being the Ramayana of Valmiki.
      This work is also called, in popular parlance, Tulsi Ramayana, Tulsikrit Ramayana, Tulsidas Ramayana or simply Manas. The word Ramcharitmanas literally means "Lake of the deeds of Rama".[1] It is considered one of the greatest works of Hindu literature. The work has variously been acclaimed as "the living sum of Indian culture", "the tallest tree in the magic garden of medieval Indian poetry", "the greatest book of all devotional literature" and "the best and most trustworthy guide to the popular living faith of the Indian people" Part of a series on Hindu scriptures and texts ShrutiSmriti List
      Vedas Upanishads Other scriptures Related Hindu texts Vedangas Puranas Itihasa Sangam literature Shastras and sutras Timeline vte
      Tulsidas was a great scholar of Sanskrit, but due to limited accessibility of the language, he chose to write it in the vernacular, Awadhi, making his work more accessible to the general public.[3] Tradition has it that Tulsidas had to face much criticism from the Sanskrit scholars of Varanasi for being a vernacular poet. However, Tulsidas remained steadfast in his resolve to simplify the knowledge contained in the Vedas, the Upanishads and the Puranas to the common people. Subsequently, his work was widely accepted.`
    },
    {
      bookImg: "",
      book_title: "श्रीरामचरितमानस Ramcharitmanas",
      category: "Scripture",
      price: "699",
      status: "published",
      bookDescription: `Ramcharitmanas (Devanagari: रामचरितमानस rāmacaritamānasa), is an epic poem in the Awadhi language, composed by the 16th-century Indian bhakti poet Tulsidas. It has many inspirations, the primary being the Ramayana of Valmiki.
      This work is also called, in popular parlance, Tulsi Ramayana, Tulsikrit Ramayana, Tulsidas Ramayana or simply Manas. The word Ramcharitmanas literally means "Lake of the deeds of Rama".[1] It is considered one of the greatest works of Hindu literature. The work has variously been acclaimed as "the living sum of Indian culture", "the tallest tree in the magic garden of medieval Indian poetry", "the greatest book of all devotional literature" and "the best and most trustworthy guide to the popular living faith of the Indian people" Part of a series on Hindu scriptures and texts ShrutiSmriti List
      Vedas Upanishads Other scriptures Related Hindu texts Vedangas Puranas Itihasa Sangam literature Shastras and sutras Timeline vte
      Tulsidas was a great scholar of Sanskrit, but due to limited accessibility of the language, he chose to write it in the vernacular, Awadhi, making his work more accessible to the general public.[3] Tradition has it that Tulsidas had to face much criticism from the Sanskrit scholars of Varanasi for being a vernacular poet. However, Tulsidas remained steadfast in his resolve to simplify the knowledge contained in the Vedas, the Upanishads and the Puranas to the common people. Subsequently, his work was widely accepted.`
    },
  ])

  const navigate = useNavigate();

  return (
    <main className='main-container'>
      <div className='rounded-xl p-4 text-black' style={{ boxShadow: "0px 0px 4px 1px lightgray" }}>
        <div className='mb-5'>
          <Button onClick={() => navigate("/admin/add-books")} type='submit' className='w-[200px] md:w-1/4 flex font-extrabold justify-center items-center gap-2' variant='contained' sx={{ padding: "8px", backgroundColor: "#db2777" }}>
            <IoMdAdd className='text-white text-lg' /> Add Books </Button>
        </div>
        <div className='main-title mb-5'>
          <h3 className='text-black text-xl font-bold'>listed Books</h3>
        </div>
        <div className="overflow-x-auto max-h-[600px] overflow-y-scroll " id='tableData'>
          <table className="min-w-full divide-y divide-gray-200 text-left scroll">
            <thead className="bg-gray-100 sticky top-0 z-10">
              <tr>
                <th className="px-4 py-3 text-sm  font-bold uppercase tracking-wider">Book Image</th>
                <th className="px-4 py-3 text-sm  font-bold uppercase tracking-wider">Book Title</th>
                <th className="px-4 py-3 text-sm  font-bold uppercase tracking-wider">Category</th>
                <th className="px-4 py-3 text-sm  font-bold uppercase tracking-wider">Price</th>
                <th className="px-4 py-3 text-sm  font-bold uppercase tracking-wider">Status</th>
                <th className="px-4 py-3 text-sm  font-bold uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {
                books.length && (
                  books.map((item, index) =>
                    <tr className='p-2'>
                      <td className="py-2 px-4">
                        <img src={ramBook} alt="product" className='myImg rounded-lg w-32 h-24 object-cover' />
                      </td>
                      <td className="px-4 py-2 text-sm ">
                        {item.book_title}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm ">{item.category}</td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm ">₹{item.price}</td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm  text-green-600">
                        <div className='flex justify-evenly items-center gap-5'>
                          {item.status}
                          <FormControlLabel
                            control={<IOSSwitch className="" defaultChecked />}
                          />
                        </div>
                      </td>
                      <td className="px-4 py-2 text-xs md:text-sm">
                        <div className='flex justify-evenly items-center gap-3 z-0'>
                          <Tooltip title='See Details'>
                            <IconButton onClick={() => { navigate(`/admin/see-details/${index}`) }}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Edit'>
                            <IconButton >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title='Delete'>
                            <IconButton >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  )
                )
              }
            </tbody>
          </table>

        </div>
      </div>
    </main>
  )
}

export default BookAndAudio